import React, { useState, useEffect } from 'react';
import { toPng } from 'html-to-image';
import { injectSpeedInsights } from '@vercel/speed-insights';
import { put } from "@vercel/blob";
import useMetaTags from './useMetaTags';
import { Analytics } from "@vercel/analytics/react"

injectSpeedInsights();




const incomeData = [
  { percentile: 100, threshold: 0 },
  { percentile: 50, threshold: 71163 },
  { percentile: 10, threshold: 290848 },
  { percentile: 1, threshold: 2073846 },
  { percentile: 0.1, threshold: 8220379 },
  { percentile: 0.01, threshold: 34606044 },
  { percentile: 0.001, threshold: 200198548 }
];

const totalPopulation = 922344832;

const formatIndianNumber = (num) => {
  const str = num.toString();
  let result = '';
  let firstComma = true;
  
  for (let i = str.length - 1, count = 0; i >= 0; i--) {
    if (count === 3 && firstComma) {
      result = ',' + result;
      firstComma = false;
      count = 0;
    } else if (count === 2 && !firstComma) {
      result = ',' + result;
      count = 0;
    }
    result = str[i] + result;
    count++;
  }
  return result;
};

const removeCommas = (str) => str.replace(/,/g, '');

const calculatePercentileLogarithmic = (income) => {
  // If the income is less than or equal to the threshold of the second income data point
  if (income <= incomeData[1].threshold) {
    // Get the percentile and income values for the first and second data points

    const lowerPercentile = incomeData[0].percentile;
    const upperPercentile = incomeData[1].percentile;
    const lowerIncomeLog = Math.log(incomeData[0].threshold + 1); // Adding 1 to avoid log(0)
    const upperIncomeLog = Math.log(incomeData[1].threshold);
    const incomeLog = Math.log(income + 1); // Adding 1 to avoid log(0)

    // Interpolate the percentile using logarithmic scaling
    const interpolatedPercentile = lowerPercentile + 
    (upperPercentile - lowerPercentile) * 
    (incomeLog - lowerIncomeLog) / 
    (upperIncomeLog - lowerIncomeLog);

    // Return the interpolated percentile
    return interpolatedPercentile;
  }
  
  if (income >= incomeData[incomeData.length - 1].threshold) return incomeData[incomeData.length - 1].percentile;

  for (let i = 2; i < incomeData.length; i++) {
    if (income < incomeData[i].threshold) {
      const lowerPercentile = incomeData[i-1].percentile;
      const upperPercentile = incomeData[i].percentile;
      const lowerIncomeLog = Math.log(incomeData[i-1].threshold);
      const upperIncomeLog = Math.log(incomeData[i].threshold);
      const incomeLog = Math.log(income);

      // Interpolate using logarithmic scaling
      const interpolatedPercentile = lowerPercentile + 
        (upperPercentile - lowerPercentile) * 
        (incomeLog - lowerIncomeLog) / 
        (upperIncomeLog - lowerIncomeLog);

      return interpolatedPercentile;
    }
  }

};

const IncomeDistributionChart = ({ userPercentile }) => {
  const width = 600;
  const height = 400;
  const margin = { top: 20, right: 70, bottom: 70, left: 100 }; // Increased bottom and left margins
  const gap = 10; // Added gap

  const xCategories = incomeData.map(d => d.percentile);
  console.log(xCategories);
  const xScale = (percentile) => {
    const index = xCategories.indexOf(percentile);
    console.log(`Percentile: ${percentile}, Index: ${index}`);
    if (index === -1) {
      // If the percentile is not found in xCategories, perform logarithmic extrapolation

      let lowerIndex = -1;
      for (let i = 0; i < xCategories.length; i++) {
        if (xCategories[i] < percentile) {
          lowerIndex = i - 1;
          break;
        }
      }
      const upperIndex = lowerIndex + 1;
      console.log(lowerIndex, upperIndex);

      if (lowerIndex < 0 || upperIndex >= xCategories.length) {
        // If the percentile is out of bounds, return the closest bound
        console.log(percentile < xCategories[0] ? margin.left : width - margin.right);
        return percentile < xCategories[0] ? margin.left : width - margin.right;
      }

      const lowerPercentile = xCategories[lowerIndex];
      const upperPercentile = xCategories[upperIndex];
      const lowerX = margin.left + (lowerIndex / (xCategories.length - 1)) * (width - margin.left - margin.right);
      const upperX = margin.left + (upperIndex / (xCategories.length - 1)) * (width - margin.left - margin.right);

      const logScale = (value, min, max) => (Math.log(value) - Math.log(min)) / (Math.log(max) - Math.log(min));

      const interpolatedX = lowerX + (upperX - lowerX) * logScale(percentile, lowerPercentile, upperPercentile);
      console.log(interpolatedX);
      return interpolatedX;
    }
    console.log(index);
    return margin.left + (index / (xCategories.length - 1)) * (width - margin.left - margin.right);
  };

  const yScale = (income) => {
    if (income === 0) return height - margin.bottom;
    const minIncome = Math.log10(1);
    const maxIncome = Math.log10(200198548);
    const scale = (Math.log10(income) - minIncome) / (maxIncome - minIncome);
    return height - margin.bottom - scale * (height - margin.top - margin.bottom);
  };

  const yTicks = [0, 10_000, 1_00_000, 10_00_000, 1_00_00_000, 10_00_00_000, 20_00_00_000];

  // Find the nearest known percentile for positioning the marker
  const nearestPercentile = xCategories.reduce((prev, curr) => 
    Math.abs(curr - userPercentile) < Math.abs(prev - userPercentile) ? curr : prev
  );

  return (
    <div>
    <svg width="100%" height="100%" viewBox={`0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`} preserveAspectRatio="xMidYMid meet" style={{ background: 'white' }}>
      <g transform={`translate(${margin.left},${margin.top})`}>
        {/* X-axis */}
        <line x1={0} y1={height + gap} x2={width} y2={height + gap} stroke="black" />
        {xCategories.map(tick => (
          <g key={tick} transform={`translate(${xScale(tick) - margin.left},${height + gap})`}>
            <line y2="6" stroke="black" />
            <text y="20" textAnchor="middle" fontSize="10">{tick}%</text>
          </g>
        ))}
        <text x={width / 2} y={height + 55 + gap} textAnchor="middle">Percentile</text> {/* Adjusted for increased bottom margin */}

        {/* Y-axis */}
        <line x1={-gap} y1={0} x2={-gap} y2={height} stroke="black" />
        {yTicks.map(tick => (
          <g key={tick} transform={`translate(${-gap},${yScale(tick) - margin.top})`}>
            <line x2="-6" stroke="black" />
            <text x="-10" textAnchor="end" fontSize="10">{formatIndianNumber(tick)}</text>
          </g>
        ))}
        <text transform={`translate(-65,${height / 2}) rotate(-90)`} textAnchor="middle">Income (₹, log scale)</text> {/* Adjusted for increased left margin */}

        {/* Plot points and line */}
        <path
          d={incomeData.map((point, index) => 
            `${index === 0 ? 'M' : 'L'} ${xScale(point.percentile) - margin.left} ${yScale(point.threshold) - margin.top}`
          ).join(' ')}
          fill="none"
          stroke="blue"
          strokeWidth="2"
        />
        {incomeData.map((point, index) => (
          <circle
            key={index}
            cx={xScale(point.percentile) - margin.left}
            cy={yScale(point.threshold) - margin.top}
            r="4"
            fill="blue"
          />
        ))}

        {/* "You are here" marker */}
        {userPercentile && (
          <g transform={`translate(${xScale(userPercentile) - margin.left}, ${height - 10})`}>
            {/* <g transform={`translate(${xScale(tick) - margin.left},${height + gap})`> */}
            <line x1="0" y1="-10" x2="0" y2={-height} stroke="red" strokeWidth="2" strokeDasharray="5,5" />
            <text x="5" y="-height" fill="red" fontFamily="cursive" fontSize="16">
              You are here
            </text>
          </g>
        )}
      </g>
    </svg>
    </div>
  );
};

const PercentileMessages = ({ userPercentile, randomSeed }) => {
  if (userPercentile === undefined || userPercentile === null || isNaN(userPercentile)) {
    return null;
  }

  const randomValue = randomSeed < 0.5;

  if (userPercentile <= 0.001) {
    return (
      <>
        {randomValue ? (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Top 0.001%! Looks like Ambani might need to borrow your private jet for a change!</p>
        ) : (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Congrats on making it to the top 0.001%! You’ve officially reached 'No, we don't discuss money, darling' level!</p>
        )}
      </>
    );
  } else if (userPercentile > 0.001 && userPercentile <= 0.01) {
    return (
      <>
        {randomValue ? (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Top 0.01%! You’re so elite, even your dog probably has a trust fund.</p>
        ) : (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Welcome to the top 0.01%! Next stop: Naming a college after yourself!</p>
        )}
      </>
    );
  } else if (userPercentile > 0.01 && userPercentile <= 0.1) {
    return (
      <>
        {randomValue ? (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Top 0.1%! You’re one of the few people who can afford to say, ‘Paisa hai, toh sab hai!' without irony.</p>
        ) : (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Cheers to being in the top 0.1%! Even your investments have their own investments now!</p>
        )}
      </>
    );
  } else if (userPercentile > 0.1 && userPercentile <= 1) {
    return (
      <>
        {randomValue ? (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Top 1%! Time to upgrade from 'sasta jugaad' to 'village-wide real estate'!</p>
        ) : (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Congrats on being in the top 1%! Your biggest problem now is finding friends who won't ask for a ‘small loan’!</p>
        )}
      </>
    );
  } else if (userPercentile > 1 && userPercentile <= 10) {
    return (
      <>
        {randomValue ? (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Top 10%! You're rich enough to consider foreign vacations, but still counting the days till the next big sale!</p>
        ) : (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Congrats on the top 10%! You're the reason why premium brands now have EMI options!</p>
        )}
      </>
    );
  } else if (userPercentile > 10 && userPercentile <= 50) {
    return (
      <>
        {randomValue ? (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>Cheers to being in the top 50%! Just rich enough to upgrade your Netflix plan, but still hunting for cashback offers!</p>
        ) : (
          <p style={{ marginTop: '20px', fontStyle: 'italic' }}>At least you’re safe from Nirmala Tai, because, well, there’s not much to save!</p>
        )}
      </>
    );
  } else if (userPercentile > 50 && userPercentile <= 100) {
    return (
      <p style={{ marginTop: '20px', fontStyle: 'italic' }}>You're living proof that wealth isn’t everything – but a little more wouldn’t hurt, right?</p>
    );
  }
  return null;
};

const App = () => {
  const [income, setIncome] = useState('');
  const [result, setResult] = useState(null);
  const [randomSeed, setRandomSeed] = useState(Math.random());
  const [userPercentile, setUserPercentile] = useState(null);
  const [chartUrl, setChartUrl] = useState(null);

  useEffect(() => {
    document.title = "Income Check";
    const link = document.createElement('link');
    link.rel = 'icon';
    link.href = 'data:image/svg+xml,<svg xmlns=%22http://www.w3.org/2000/svg%22 viewBox=%220 0 36 36%22><text y=%2232%22 font-size=%2232%22>🇮🇳</text></svg>';
    document.head.appendChild(link);
  }, []);

  const handleIncomeChange = (e) => {
    const value = removeCommas(e.target.value);
    if (/^\d*$/.test(value)) {
      setIncome(formatIndianNumber(value));
    }
  };

  const handleCaptureChart = async () => {
    const chartElement = document.querySelector('.chart-container');
    const dataUrl = await toPng(chartElement);
    return dataUrl;
  };

  const uploadToVercelBlob = async (imageDataUrl, percentile) => {
    const base64Data = imageDataUrl.split(',')[1]; // Extract base64 string without metadata

    try {
      // Check if the image for the given percentile already exists
      const existingImageResponse = await fetch(`/api/check-image?percentile=${percentile}`);
      const existingImageData = await existingImageResponse.json();

      if (existingImageData.url) {
        console.log("image for percentile " + percentile + " exists ");
        console.log(existingImageData);
        return existingImageData.url;
        
      }
      console.log("image for percentile " + percentile + " does not exist ");
      const response = await fetch('/api/upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ image: base64Data, percentile }),  // Include percentile in the request body
      });

      const data = await response.json();
      return data.url;  // URL of the uploaded image
     
    } catch (error) {
      console.log(error);
    }
  };

  const handleCalculate = async () => {
    const userIncome = parseFloat(removeCommas(income));
    if (isNaN(userIncome)) {
      setResult('Please enter a valid income');
      setUserPercentile(null);
      return;
    }

    const percentile = calculatePercentileLogarithmic(userIncome);
    setUserPercentile(percentile);
    const peopleRicher = Math.round(totalPopulation * (percentile / 100));

    setResult(`You are in the top <b>${percentile.toFixed(3)}%</b> of earners in India.<br> 
               Approximately <b>${formatIndianNumber(peopleRicher)}</b> people earn more than you.`);

  };



  useMetaTags({
    'og:title': userPercentile ? `My Income Percentile: ${userPercentile}%` : 'Income Percentile Checker',
    'og:description': 'Check your income percentile in India',
    'og:image': chartUrl || 'https://indiaincomecheck.in/default-image.png',
    'og:url': 'https://indiaincomecheck.in',
    'og:type': 'website',
    'twitter:card': 'summary_large_image',
    'twitter:title': userPercentile ? `My Income Percentile: ${userPercentile}%` : 'Income Percentile Checker',
    'twitter:description': 'Check your income percentile in India',
    'twitter:image': chartUrl || 'https://indiaincomecheck.in/default-image.png',
  });
  
  const handleShare = async () => {
    const imageData = await handleCaptureChart();
    const imageUrl = await uploadToVercelBlob(imageData, userPercentile.toFixed(3)); // Upload to Vercel Blob and get URL
    setChartUrl(imageUrl);
    
    const shareText = `I am at the top ${userPercentile.toFixed(3)}% of earners in India. Find yours at `
    const shareUrl = `https://indiaincomecheck.in`;
    const twitterIntentUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(shareText)}`;
    window.open(twitterIntentUrl, '_blank');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleCalculate();
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90vh', color: 'black', padding: '10px', boxSizing: 'border-box' }}>
        <Analytics />
        <div style={{ width: '100%', maxWidth: '600px' }}>
          <div className="wrap1" style={{ textAlign: 'center', display: 'inline-block' }}>
            <h1 style={{ top: '0px',margin: '0px' }}>🇮🇳 Income Percentile Calculator</h1>
            <div style={{ paddingLeft: '0px', textAlign: 'left' }}>
              <p style={{ fontSize: '12px', color: 'grey' }}>built by <a href="https://x.com/alokbishoyi97" target="_blank" rel="noopener noreferrer" style={{ color: 'grey' }}>@alokbishoyi97</a></p>
            </div>
          </div>
          <div style={{ marginBottom: '20px' }}>
            <input
              type="text"
              placeholder="Enter your annual income in ₹"
              value={income}
              onChange={handleIncomeChange}
              onKeyPress={handleKeyPress}
              style={{ width: '100%', padding: '10px', boxSizing: 'border-box' }}
            />
          </div>
          <div style={{ textAlign: 'center' }}>
            <button onClick={handleCalculate} style={{ padding: '10px 20px', cursor: 'pointer' }}>Calculate</button>
          </div>

            {result && (
              <div style={{ marginTop: '20px', textAlign: 'center' }}>
                <p dangerouslySetInnerHTML={{ __html: result }}></p>
                <div className="chart-container" style={{ width: '100%', overflowX: 'auto' }}>
                  <IncomeDistributionChart userPercentile={userPercentile} />
                  <PercentileMessages userPercentile={userPercentile} randomSeed={randomSeed} />
                </div>
              </div>
            )}
        </div>
      </div>

      <div >
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', fontSize: '12px', color: 'grey', marginRight: '30px', marginBottom: '50px' }}>
      
      <div style={{ marginTop: '20px' }}>
        <a href="https://wid.world/wp-content/uploads/2024/03/WorldInequalityLab_WP2024_09_Income-and-Wealth-Inequality-in-India-1922-2023_Final.pdf" target="_blank" rel="noopener noreferrer" style={{ color: 'grey' }}>Data Source</a>
      </div>
    </div>
    </div>
</div>
  );
};

export default App;