import { useEffect } from 'react';

const useMetaTags = (tags) => {
  useEffect(() => {
    const updateMetaTag = (name, content) => {
      let element = document.querySelector(`meta[name="${name}"]`) || 
                    document.querySelector(`meta[property="${name}"]`);
      
      if (!element) {
        element = document.createElement('meta');
        element.setAttribute(name.includes('og:') ? 'property' : 'name', name);
        document.head.appendChild(element);
      }
      
      element.setAttribute('content', content);
    };

    Object.entries(tags).forEach(([name, content]) => {
      if (content) {
        updateMetaTag(name, content);
      }
    });

    // Cleanup function to remove added meta tags when component unmounts
    return () => {
      Object.keys(tags).forEach(name => {
        const element = document.querySelector(`meta[name="${name}"]`) || 
                        document.querySelector(`meta[property="${name}"]`);
        if (element && element.parentNode) {
          element.parentNode.removeChild(element);
        }
      });
    };
  }, [tags]);
};

export default useMetaTags;